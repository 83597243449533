<template>
  <div class="radio_container required err_message_box">
    <p>
      <span class="txt-red txt-regular" v-if="required">* </span>
      {{ item.label }}
    </p>
    <span class="err_message txt-red small">{{errorMsg}}</span>
    <template v-for="(option, index) in item.options">
      <label
        :key="`option_${index}`"
        class="p"
        v-if="option.num"
      >
        <input
          :id="option.value"
          :class="{ isChosen: chosen && chosen === option.value }"
          :checked="chosen && chosen === option.value"
          type="radio"
          :name="item.uuid"
          :value="option.value"
          :error="error"
          @change="test(); chooseFraction()"
          v-model="fraction"
        >{{ option.name }} {{ option.num }} 點
        <div class="checkmark"></div>
      </label>
    </template>
  </div>
</template>

<script>
export default {
  name: 'FormRadioFraction',
  props: [
    'value',
    'item',
    'required',
    'updateFraction',
    'chosen',
  ],
  data() {
    return {
      errorMsg: '',
      fraction: '',
    };
  },
  created() {
    if (this.chosen) {
      this.fraction = this.chosen;
    }
  },
  methods: {
    test() {
      if (this.required) {
        this.errorMsg = !this.fraction ? '請至少選擇一項' : '';
      }
    },
    chooseFraction() {
      this.$emit('updateFraction', {
        uuid: this.item.uuid,
        fraction: this.fraction,
      });
    },
  },
  computed: {
    error() {
      if (this.errorMsg !== '') {
        return true;
      }
      return false;
    },
  },
  watch: {
    value() {
      if (this.required) {
        if (!this.fraction) {
          this.errorMsg = '請至少選擇一項';
        }
      }
    },
  },
};
</script>
