<template>
  <div class="checkbox_container required">
    <p>
      <span class="txt-red txt-regular" v-if="required"
      >*</span>
      請選擇其他積分 <span class="txt-regular">(可複選)</span>
    </p>
    <label
      v-for="(value, key) in item.fractions"
      :key="key"
      :for="`${item.uuid}_${key}`"
      v-show="value * 1 !== 0"
    >
      <p class="checkbox_item_name">{{ fractionName(key) }}積分</p>
      <!-- <p class="checkbox_item_name">{{ fractionName(key) }}積分 {{ value }} 點</p> -->
      <input
        :id="`${item.uuid}_${key}`"
        :class="{ isChosen: chosen && chosen.indexOf(key) !== -1 }"
        v-model="fractionsArr"
        type="checkbox"
        :value="key"
      >
      <span class="checkmark"></span>
    </label>

    <!-- <label for="none">
      <p class="checkbox_item_name">不申請積分</p>
      <input id="none" name="point_category[]" type="checkbox" value="none">
      <span class="checkmark"></span>
    </label> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FormCheckboxFraction',
  props: [
    'item',
    'updateFraction',
    'required',
    'chosen',
  ],
  data() {
    return {
      errorMsg: '',
      id: null,
      fractionsArr: [],
    };
  },
  watch: {
    fractionsArr() {
      this.chooseFraction();
    },
  },
  computed: {
    ...mapGetters([
      'hoursType',
    ]),
    error() {
      if (this.errorMsg !== '') {
        return true;
      }
      return false;
    },
  },
  methods: {
    test() {
      if (this.required) {
        this.errorMsg = this.fractionsArr === [] ? '請至少選擇一項' : '';
      }
    },
    chooseFraction() {
      this.$emit('updateFraction', {
        uuid: this.item.uuid,
        fraction: this.fractionsArr,
      });
    },
    fractionName(fractionKey) {
      let renderName = '';
      for (let i = 0; i < this.hoursType.length; i += 1) {
        const item = this.hoursType[i];
        if (parseInt(fractionKey, 10) === parseInt(item.id, 10)) {
          renderName = item.name;
        }
      }
      return renderName;
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.id = this._uid;
  },
};
</script>
