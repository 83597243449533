<template>
  <div class="err_message_box">
    <label class="p" v-show="item.label" :for="id">
        <span class="txt-red txt-regular" v-if="item.required">*</span>
        {{item.label}}
      <!-- <router-link v-if="!!item.link && !isLoggedIn"
        :to="item.link" class="">
        {{item.linkLabel}}
      </router-link> -->
    </label>
    <span class="err_message txt-red small">{{errorMsg}}</span>
    <input
      :id="id"
      :value="value"
      :rules="rules"
      :type="item.type"
      :error="error"
      :disabled="disabled"
      :maxlength="maxlength"
      :placeholder="item.placeholder"
      @blur="test(); verify();"
      @input="$emit('input', $event.target.value)"
      @focus="resetSubmited()"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapActions } from 'vuex';
import { idVerify } from '@/lib/http';

export default {
  name: 'FormInput',
  props: {
    value: {},
    type: {
      type: String,
      default: 'text',
    },
    rules: Array,
    maxlength: Number,
    // label: String,
    placeholder: String,
    item: {
      placeholder: String,
      label: String,
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
    hasSubmited: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    idCheck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorMsg: '',
      id: null,
    };
  },
  watch: {
    value(v) {
      if (!this.hasSubmited) {
        this.errorMsg = this.validate(v) ? this.validate(v) : '';
      }
    },
  },
  computed: {
    hasRules() {
      return this.rules !== undefined
        && this.rules !== null
        && this.rules.length > 0;
    },
    error() {
      if (this.hasRules && this.errorMsg !== '') {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    resetSubmited() {
      if (this.hasSubmited) {
        this.$emit('update:hasSubmited', false);
      }
    },
    test() {
      this.errorMsg = this.validate(this.value) ? this.validate(this.value) : '';
    },
    verify() {
      if (this.idCheck) {
        if (this.value.match(/^[A-Z][1-2]\d{8}$/)) {
          idVerify({ id_number: this.value }).then((result) => {
            if (result) {
              const userApply = _.get(result, 'apply', undefined);
              const userLevel = _.get(result, 'level', undefined);

              let userApplyReason = _.get(result, 'memo.apply', '');
              if (userApplyReason) {
                userApplyReason = `(${userApplyReason})`;
              }

              if (parseInt(userLevel, 10) === 1 || parseInt(userLevel, 10) === 2) {
                this.openModal({
                  message: '您已成為本會會員，請直接前往登入頁面進行登入',
                  btnLink: '/login',
                });
              } else if (userApply === 1) {
                this.openModal({
                  message: `
                  入會申請未通過<br>
                  ${userApplyReason}<br><br>
                  詳情請洽學會<br>
                  聯絡電話：0988-206590<br>
                  電子郵件：hmca.tw@gmail.com
                  `,
                });
              }
            }
          });
        }
      }
    },
    validate(val = this.value) {
      if (this.hasRules === true) {
        const errorMessage = [];
        this.rules.forEach((rule) => {
          if (typeof rule === 'function') {
            if (rule(val) !== true) {
              errorMessage.push(rule(val));
            }
          } else if (typeof rule === 'object') {
            if (val && !val.match(rule[0])) {
              errorMessage.push(rule[1]);
            }
          }
        });
        if (errorMessage.length) {
          return errorMessage[0];
        }
      }
      return '';
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.id = this._uid;
  },
};
</script>
